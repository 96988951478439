import React from 'react';
import { NotionRenderer } from 'react-notion-x';
import notion from '../../public/notion.json';
import '../style/style.css';

export default function MainPage() {
  return (
    <>
      <NotionRenderer recordMap={notion} fullPage={true} darkMode={false} />
    </>
  );
}
